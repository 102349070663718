import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { configAxios } from "./util/axios";
import App from './containers/App';

//Call the configure Axios function
configAxios();

//Render the react app and add the Router that handles the containers
ReactDOM.render(
  <React.StrictMode>
    <Router history={createBrowserHistory()}>
      <App />
    </Router >
  </React.StrictMode>,
  document.getElementById('root')
);
