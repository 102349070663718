import * as React from "react";
import { Switch, Route } from "react-router-dom";
import loadable from 'react-loadable';
import Loading from "./components/loading/Loading";

//Ressource Overview Container
const AsyncRessourceOverviewContainer = loadable({ 
    loader: () => 
    import(/* webpackChunkName: "RessourceOverviewContainer" */ "./containers/views/RessourceOverviewContainer"), 
    loading: Loading
});

//Ressource Calendar Container
const AsyncRessourceCalendarContainer = loadable({ 
    loader: () => 
    import(/* webpackChunkName: "RessourceCalendarContainer" */ "./containers/views/RessourceCalendarContainer"), 
    loading: Loading
});

const Routes: React.FC = () => {
    
    //Return function that switches between the diffrent containers based on the url
    return (
        <Switch>
            <Route exact path="/" render={() => <AsyncRessourceOverviewContainer />} />
            <Route exact path="/en" render={() => <AsyncRessourceOverviewContainer />} />
            <Route exact path="/slot-booking/:id" render={() => <AsyncRessourceCalendarContainer />} />
            <Route exact path="/en/slot-booking/:id" render={() => <AsyncRessourceCalendarContainer />} />
       </Switch>
    );
}

export default Routes;