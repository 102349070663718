import * as React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
    isLoading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "fixed",
            textAlign: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 100
        },
    })
);

const Loading: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const renderIsLoading = () => {
        if (props.isLoading) {
            return (
                <div className={classes.wrapper}>
                    <SyncLoader
                        size={20} 
                        color={"#ffffff"}
                        loading />
                </div>
            );
        }
    }

    return (<>{renderIsLoading()}</>);
}

export default Loading;