import React from 'react';
import Routes from "../routes";
import Header from "../components/header/Header";

//Main App
function App() {
  return (
    <div className="content">
      <Header />
      <Routes />
    </div>
  );
}

export default App;
