import axios from 'axios';

//Configuring the axios with the default Url and putting the 
//API-Key into the headers.Authorization 
export const configAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.interceptors.request.use(function (config) {
    const token = `71a144a0-0112-4da1-b318-e94b0572fecb`;
    config.headers.Authorization = token;

    return config;
  });
};