import React from "react";
import logo from "../../_img/logo.svg";
import germanFlag from "../../_img/germanFlag.svg";
import englishFlag from "../../_img/englishFlag.svg";

const Header: React.FC = () => {

    //Return function where the header of the site is returned
    return (
        <div className="headerWrapper">
            <a href="/">
                <img src={logo} className="logo" alt="logo" />
            </a>
            <div className="languageWrapper">
                <a href="/">
                    <img src={germanFlag} className="language" alt="german flag" />
                </a>
                <a href="/en">
                    <img src={englishFlag} className="language" alt="english flag" />
                </a>
            </div>
        </div>
    );
};

export default Header;